<template>
<div id="page-articulos-precio">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Ajustar Precios por Artículo" class="elevation-1 px-5 py-3">
          <v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :color="articulos_en_rojo>0?'red':'success'" @click.native="excel_precios_rojos()">
                <v-icon>mdi-file-excel</v-icon> {{articulos_en_rojo}} Artículos con Precios en Rojo
              </v-btn>
            </v-card-actions>

            <v-col sm="6" md="6" lg="6">
                  <v-autocomplete dense v-model="id_proveedor" :items="proveedores_combo" :hide-no-data="true"
                  :loading="loadingProveedores"
                  ref="selectproveedor" placeholder="Ingrese nombre de proveedor" clearable
                  item-text="nombre" item-value="_id" @change="consultarProveedor_combo()" label="Filtro Opcional Proveedor">
                  </v-autocomplete>                  
              </v-col>

              <v-client-table ref="vuetableProveedor" v-if="id_proveedor!=null && id_proveedor!=undefined && id_proveedor!=''" :data="registros.proveedor" 
                :columns="columns_proveedor" :options="options_proveedor" class="elevation-2 pa-2" :key="lakey" @row-click="selectRow">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registros</v-alert>
                </template>

                <template slot="codigo_barras" slot-scope="props">
                  {{props.row.codigo_barras.join(", ")}}
                </template>

                <template slot="categoria" slot-scope="props">
                  {{props.row.categoria[0]}}
                </template>

                <template slot="existencia" slot-scope="props">
                    <div style="text-align:center !important;">
                        <v-chip x-small color="gray" text-color="black" title="Ver Existencias" v-tippy 
                            @click="ver_existencias(props.row)">
                            <b>{{formatNumberDecCero(props.row.existencia,2)}}</b>
                        </v-chip>
                    </div>                    
                </template>

                <template slot="precio_compra_con_iva" slot-scope="props">
                    <div style="text-align:center !important;">                        
                      <v-chip x-small color="gray" text-color="black" title="Ver Historial" v-tippy 
                            @click="getCompras(props.row._id)">
                            <b>${{props.row.precio_compra_con_iva}}</b>
                        </v-chip>
                    </div>                    
                </template>

                <template slot="precio_compra_promedio" slot-scope="props">
                    <div style="text-align:center !important;" title="El Precio Promedio se calcula en base al precio de compra de las existencias. Si muestra 0 es que no hay existencias positivas del producto." v-tippy>
                            <b>${{formatNumberDec(parseFloat(props.row.precio_compra_promedio).toFixed(2),2)}}</b>
                    </div>                    
                </template>

                <template slot="publico" slot-scope="props">
                    <div v-if="parseFloat(props.row.publico)>parseFloat(props.row.precio_compra_con_iva) || parseFloat(props.row.precio_compra_con_iva) == 0 " style=" text-align:center !important;">
                        <v-chip x-small color="gray" text-color="green" title="Cambiar Precio" v-tippy @click="cambiar(props.row, 1,  props.index)">
                            <b>${{props.row.publico}}</b>
                        </v-chip>
                    </div>
                    <div v-else-if="parseFloat(props.row.publico)<=parseFloat(props.row.precio_compra_con_iva) && parseFloat(props.row.precio_compra_con_iva) > 0 " style=" text-align:center !important;">
                        <v-chip x-small color="red" text-color="white" title="Cambiar Precio" v-tippy @click="cambiar(props.row, 1, props.index)">
                            <b>${{props.row.publico}}</b>
                        </v-chip>
                    </div>
                    <div v-else-if="props.row.publico=='N/A'" style=" text-align:center !important;">
                            N/A
                    </div>
                </template>
                <template slot="diferencia_precio_publico" slot-scope="props">
                    <div v-if="props.row.publico=='N/A'" style="text-align:right">&nbsp;</div>
                    <div v-else style="text-align:right" @click="getRegistro(props.row._id)">${{(parseFloat(props.row.publico) - parseFloat(props.row.precio_compra_con_iva)).toFixed(2)}}</div>                    
                </template>
                <template slot="utilidad_publico" slot-scope="props">
                    <div v-if="props.row.precio_compra_con_iva==0" class="bg-amarillo" style="text-align:right" @click="getRegistro(props.row._id)">{{(100).toFixed(2)}}%</div>
                    <div v-else-if="props.row.publico=='N/A'" style="text-align:right">&nbsp;</div>
                    <div v-else style="text-align:right" @click="getRegistro(props.row._id)" :class="((parseFloat(props.row.publico)/parseFloat(props.row.precio_compra_con_iva))>1.4?'bg-amarillo':'')">
                        {{
                          (((parseFloat(props.row.publico)/parseFloat(props.row.precio_compra_con_iva))-1)*100).toFixed(2)
                        }}%
                      </div>
                </template>

                <template slot="mayoreo" slot-scope="props">
                    <div v-if="parseFloat(props.row.mayoreo)>parseFloat(props.row.precio_compra_con_iva) || parseFloat(props.row.precio_compra_con_iva) == 0 " style=" text-align:center !important;">
                        <v-chip x-small color="gray" text-color="green" title="Cambiar Precio" v-tippy @click="cambiar(props.row,2, props.index)">
                            <b>${{props.row.mayoreo}}</b>
                        </v-chip>
                    </div>
                    <div v-else-if="parseFloat(props.row.mayoreo)<=parseFloat(props.row.precio_compra_con_iva) && parseFloat(props.row.precio_compra_con_iva) > 0 " style=" text-align:center !important;">
                        <v-chip x-small color="red" text-color="white" title="Cambiar Precio" v-tippy @click="cambiar(props.row,2, props.index)">
                            <b>${{props.row.mayoreo}}</b>
                        </v-chip>
                    </div>
                    <div v-else-if="props.row.mayoreo=='N/A'" style=" text-align:center !important;">
                            N/A
                    </div>
                </template>
                <template slot="diferencia_precio_mayoreo" slot-scope="props">
                    <div v-if="props.row.mayoreo=='N/A'" style="text-align:right">&nbsp;</div>
                    <div v-else style="text-align:right" @click="getRegistro(props.row._id)">${{(parseFloat(props.row.mayoreo) - parseFloat(props.row.precio_compra_con_iva)).toFixed(2)}}</div>
                </template>
                <template slot="utilidad_mayoreo" slot-scope="props">
                    <div v-if="props.row.precio_compra_con_iva==0" style="text-align:right" @click="getRegistro(props.row._id)">{{(100).toFixed(2)}}%</div>
                    <div v-else-if="props.row.mayoreo=='N/A'" style="text-align:right">&nbsp;</div>
                    <div v-else style="text-align:right" @click="getRegistro(props.row._id)" :class="((parseFloat(props.row.mayoreo)/parseFloat(props.row.precio_compra_con_iva))>1.4?'bg-amarillo':'')">
                      {{(((parseFloat(props.row.mayoreo)/parseFloat(props.row.precio_compra_con_iva))-1)*100).toFixed(2)}}%
                    </div>
                </template>

                <template slot="mayoreo2" slot-scope="props">
                    <div v-if="parseFloat(props.row.mayoreo2)>parseFloat(props.row.precio_compra_con_iva) || parseFloat(props.row.precio_compra_con_iva) == 0 " style=" text-align:center !important;">
                        <v-chip x-small color="gray" text-color="green" title="Cambiar Precio" v-tippy @click="cambiar(props.row,3, props.index)">
                            <b>${{props.row.mayoreo2}}</b>
                        </v-chip>
                    </div>
                    <div v-else-if="parseFloat(props.row.mayoreo2)<=parseFloat(props.row.precio_compra_con_iva) && parseFloat(props.row.precio_compra_con_iva) > 0 " style=" text-align:center !important;">
                        <v-chip x-small color="red" text-color="white" title="Cambiar Precio" v-tippy @click="cambiar(props.row,3, props.index)">
                            <b>${{props.row.mayoreo2}}</b>
                        </v-chip>
                    </div>
                    <div v-else-if="props.row.mayoreo2=='N/A'" style=" text-align:center !important;">
                            N/A
                    </div>
                </template>
                <template slot="diferencia_precio_mayoreo2" slot-scope="props">
                    <div v-if="props.row.mayoreo2=='N/A'" style="text-align:right">&nbsp;</div>
                    <div v-else style="text-align:right" @click="getRegistro(props.row._id)">${{(parseFloat(props.row.mayoreo2) - parseFloat(props.row.precio_compra_con_iva)).toFixed(2)}}</div>
                </template>
                <template slot="utilidad_mayoreo2" slot-scope="props">
                    <div v-if="props.row.precio_compra_con_iva==0" style="text-align:right" @click="getRegistro(props.row._id)">{{(100).toFixed(2)}}%</div>
                    <div v-else-if="props.row.mayoreo2=='N/A'" style="text-align:right">&nbsp;</div>
                    <div v-else style="text-align:right" @click="getRegistro(props.row._id)" :class="((parseFloat(props.row.mayoreo2)/parseFloat(props.row.precio_compra_con_iva))>1.4?'bg-amarillo':'')">
                      {{(((parseFloat(props.row.mayoreo2)/parseFloat(props.row.precio_compra_con_iva))-1)*100).toFixed(2)}}%
                    </div>
                </template>

                <template slot="actions" slot-scope="props">
                  <v-speed-dial v-model="props.row.id" direction="left" open-on-hover>
                    <template v-slot:activator>
                      <v-btn x-small fab dark color="primary">
                        <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                        <v-icon v-else>reorder</v-icon>
                      </v-btn>
                    </template>
                    <v-btn x-small fab dark color="blue" @click.native="getRegistro(props.row._id)" title="Editar Registro" v-tippy>
                      <v-icon>edit</v-icon>
                    </v-btn>                    
                  </v-speed-dial>
                </template>
              </v-client-table>

            <div v-else id="datagrid" v-on:keyup.enter="registros.items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2" 
                :key="lakey" @row-click="selectRow">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>

                <template slot="codigo_barras" slot-scope="props">
                  {{props.row.codigo_barras.join(", ")}}
                </template>

                <template slot="categoria" slot-scope="props">
                  {{props.row.categoria[0]}}
                </template>

                <template slot="existencia" slot-scope="props">
                    <div style="text-align:center !important;">
                        <v-chip x-small color="gray" text-color="black" title="Ver Existencias" v-tippy 
                            @click="ver_existencias(props.row)">
                            <b>{{formatNumberDecCero(props.row.existencia,2)}}</b>
                        </v-chip>
                    </div>                    
                </template>

                <template slot="precio_compra_con_iva" slot-scope="props">
                    <div style="text-align:center !important;">                        
                      <v-chip x-small color="gray" text-color="black" title="Ver Historial" v-tippy 
                            @click="getCompras(props.row._id)">
                            <b>${{props.row.precio_compra_con_iva}}</b>
                        </v-chip>
                    </div>                    
                </template>

                <template slot="precio_compra_promedio" slot-scope="props">
                    <div style="text-align:center !important;" title="El Precio Promedio se calcula en base al precio de compra de las existencias. Si muestra 0 es que no hay existencias positivas del producto." v-tippy>
                            <b>${{formatNumberDec(parseFloat(props.row.precio_compra_promedio).toFixed(2),2)}}</b>
                    </div>                    
                </template>

                <template slot="publico" slot-scope="props">
                    <div v-if="parseFloat(props.row.publico)>parseFloat(props.row.precio_compra_con_iva) || parseFloat(props.row.precio_compra_con_iva) == 0 " style=" text-align:center !important;">
                        <v-chip x-small color="gray" text-color="green" title="Cambiar Precio" v-tippy @click="cambiar(props.row, 1,  props.index)">
                            <b>${{props.row.publico}}</b>
                        </v-chip>
                    </div>
                    <div v-else-if="parseFloat(props.row.publico)<=parseFloat(props.row.precio_compra_con_iva) && parseFloat(props.row.precio_compra_con_iva) > 0 " style=" text-align:center !important;">
                        <v-chip x-small color="red" text-color="white" title="Cambiar Precio" v-tippy @click="cambiar(props.row, 1, props.index)">
                            <b>${{props.row.publico}}</b>
                        </v-chip>
                    </div>
                    <div v-else-if="props.row.publico=='N/A'" style=" text-align:center !important;">
                            N/A
                    </div>
                </template>
                <template slot="diferencia_precio_publico" slot-scope="props">
                    <div v-if="props.row.publico=='N/A'" style="text-align:right">&nbsp;</div>
                    <div v-else style="text-align:right" @click="getRegistro(props.row._id)">${{(parseFloat(props.row.publico) - parseFloat(props.row.precio_compra_con_iva)).toFixed(2)}}</div>                    
                </template>
                <template slot="utilidad_publico" slot-scope="props">
                    <div v-if="props.row.precio_compra_con_iva==0" class="bg-amarillo" style="text-align:right" @click="getRegistro(props.row._id)">{{(100).toFixed(2)}}%</div>
                    <div v-else-if="props.row.publico=='N/A'" style="text-align:right">&nbsp;</div>
                    <div v-else style="text-align:right" @click="getRegistro(props.row._id)" :class="((parseFloat(props.row.publico)/parseFloat(props.row.precio_compra_con_iva))>1.4?'bg-amarillo':'')">
                        {{
                          (((parseFloat(props.row.publico)/parseFloat(props.row.precio_compra_con_iva))-1)*100).toFixed(2)
                        }}%
                      </div>
                </template>

                <template slot="mayoreo" slot-scope="props">
                    <div v-if="parseFloat(props.row.mayoreo)>parseFloat(props.row.precio_compra_con_iva) || parseFloat(props.row.precio_compra_con_iva) == 0 " style=" text-align:center !important;">
                        <v-chip x-small color="gray" text-color="green" title="Cambiar Precio" v-tippy @click="cambiar(props.row,2, props.index)">
                            <b>${{props.row.mayoreo}}</b>
                        </v-chip>
                    </div>
                    <div v-else-if="parseFloat(props.row.mayoreo)<=parseFloat(props.row.precio_compra_con_iva) && parseFloat(props.row.precio_compra_con_iva) > 0 " style=" text-align:center !important;">
                        <v-chip x-small color="red" text-color="white" title="Cambiar Precio" v-tippy @click="cambiar(props.row,2, props.index)">
                            <b>${{props.row.mayoreo}}</b>
                        </v-chip>
                    </div>
                    <div v-else-if="props.row.mayoreo=='N/A'" style=" text-align:center !important;">
                            N/A
                    </div>
                </template>
                <template slot="diferencia_precio_mayoreo" slot-scope="props">
                    <div v-if="props.row.mayoreo=='N/A'" style="text-align:right">&nbsp;</div>
                    <div v-else style="text-align:right" @click="getRegistro(props.row._id)">${{(parseFloat(props.row.mayoreo) - parseFloat(props.row.precio_compra_con_iva)).toFixed(2)}}</div>
                </template>
                <template slot="utilidad_mayoreo" slot-scope="props">
                    <div v-if="props.row.precio_compra_con_iva==0" style="text-align:right" @click="getRegistro(props.row._id)">{{(100).toFixed(2)}}%</div>
                    <div v-else-if="props.row.mayoreo=='N/A'" style="text-align:right">&nbsp;</div>
                    <div v-else style="text-align:right" @click="getRegistro(props.row._id)" :class="((parseFloat(props.row.mayoreo)/parseFloat(props.row.precio_compra_con_iva))>1.4?'bg-amarillo':'')">
                      {{(((parseFloat(props.row.mayoreo)/parseFloat(props.row.precio_compra_con_iva))-1)*100).toFixed(2)}}%
                    </div>
                </template>

                <template slot="mayoreo2" slot-scope="props">
                    <div v-if="parseFloat(props.row.mayoreo2)>parseFloat(props.row.precio_compra_con_iva) || parseFloat(props.row.precio_compra_con_iva) == 0 " style=" text-align:center !important;">
                        <v-chip x-small color="gray" text-color="green" title="Cambiar Precio" v-tippy @click="cambiar(props.row,3, props.index)">
                            <b>${{props.row.mayoreo2}}</b>
                        </v-chip>
                    </div>
                    <div v-else-if="parseFloat(props.row.mayoreo2)<=parseFloat(props.row.precio_compra_con_iva) && parseFloat(props.row.precio_compra_con_iva) > 0 " style=" text-align:center !important;">
                        <v-chip x-small color="red" text-color="white" title="Cambiar Precio" v-tippy @click="cambiar(props.row,3, props.index)">
                            <b>${{props.row.mayoreo2}}</b>
                        </v-chip>
                    </div>
                    <div v-else-if="props.row.mayoreo2=='N/A'" style=" text-align:center !important;">
                            N/A
                    </div>
                </template>
                <template slot="diferencia_precio_mayoreo2" slot-scope="props">
                    <div v-if="props.row.mayoreo2=='N/A'" style="text-align:right">&nbsp;</div>
                    <div v-else style="text-align:right" @click="getRegistro(props.row._id)">${{(parseFloat(props.row.mayoreo2) - parseFloat(props.row.precio_compra_con_iva)).toFixed(2)}}</div>
                </template>
                <template slot="utilidad_mayoreo2" slot-scope="props">
                    <div v-if="props.row.precio_compra_con_iva==0" style="text-align:right" @click="getRegistro(props.row._id)">{{(100).toFixed(2)}}%</div>
                    <div v-else-if="props.row.mayoreo2=='N/A'" style="text-align:right">&nbsp;</div>
                    <div v-else style="text-align:right" @click="getRegistro(props.row._id)" :class="((parseFloat(props.row.mayoreo2)/parseFloat(props.row.precio_compra_con_iva))>1.4?'bg-amarillo':'')">
                      {{(((parseFloat(props.row.mayoreo2)/parseFloat(props.row.precio_compra_con_iva))-1)*100).toFixed(2)}}%
                    </div>
                </template>

                <template slot="actions" slot-scope="props">
                  <v-speed-dial v-model="props.row.id" direction="left" open-on-hover>
                    <template v-slot:activator>
                      <v-btn x-small fab dark color="primary">
                        <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                        <v-icon v-else>reorder</v-icon>
                      </v-btn>
                    </template>
                    <v-btn x-small fab dark color="blue" @click.native="getRegistro(props.row._id)" title="Editar Registro" v-tippy>
                      <v-icon>edit</v-icon>
                    </v-btn>                    
                  </v-speed-dial>
                </template>
              </v-client-table>
              <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>

              
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal" max-width="75%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>{{ update ? "Actualizar" : "Nuevo" }} Artículo</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>        
          
            <fieldset>
              <legend><strong>Información General</strong></legend>
              <v-form ref="form" lazy-validation>
                <v-container grid-list-md @keyup.enter="updateRegistro()">
                  <v-row>
                    <v-col sm="6" md="6" lg="6">
                      <v-autocomplete disabled readonly v-model="model.codigo_barras" :items="model.codigo_barras" 
                        :hide-no-data="true" :hide-selected="true" multiple chips small-chips deletable-chips label="Código de Barras"                         
                        >
                      </v-autocomplete>                      
                    </v-col>
                    <v-col sm="6" md="6" lg="6">
                      <v-text-field disabled readonly label="Nombre" v-model="model.nombre"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6" md="6" lg="6">
                      <v-text-field label="Descripción" disabled readonly v-model="model.descripcion"></v-text-field>
                    </v-col>
                    <v-col sm="6" md="6" lg="6">
                        <v-text-field label="Categoría" disabled readonly v-model="model.categoria"></v-text-field>                      
                    </v-col>
                  </v-row>                  
                  <v-row>
                    <v-col sm="4" md="4" lg="4">
                      <v-text-field disabled readonly prepend-icon="attach_money" label="Precio Compra S/IVA" v-model="model.precio_compra"
                        ></v-text-field>
                    </v-col>
                    <v-col sm="4" md="4" lg="4">
                      <v-text-field disabled readonly prepend-icon="attach_money" label="Precio Compra C/IVA" v-model="model.precio_compra_con_iva"></v-text-field>
                    </v-col>
                    <v-col sm="4" md="4" lg="4">
                        <v-text-field label="Marca/Modelo" disabled readonly v-model="model.marca"></v-text-field>                      
                    </v-col>
                  </v-row>

                </v-container>
              </v-form>
            </fieldset>
            <fieldset>
              <legend><strong>Precios de venta:</strong></legend>
              <v-form ref="formprecios" lazy-validation>
                <v-container grid-list-md>
                  <v-spacer></v-spacer>
                  <v-btn fab dark x-small color="green" v-on:click="addNewPrecio" title="Agregar Precio" v-tippy>
                    <v-icon dark>add</v-icon>
                  </v-btn>
                  <div v-for="(detalle, index) in model.precios_venta" v-bind:key="index">
                    <v-row style="flex-wrap: nowrap;">
                      <v-col cols="3">
                        <v-autocomplete :name="'nombre['+index+']'" v-model="detalle.tipo_precio" :items="tipos_precios" :hide-no-data="true" :hide-selected="true" item-text="nombre" item-value="nombre" label="Tipo de Precio"
                          :rules="[rules.required, validaTipoPrecio]"></v-autocomplete>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field :name="'nombre['+index+']'" label="% Aumento" type="text" 
                        @blur="validaPrecioVenta(index)" class="center-input" autocomplete="off" :rules="[validaCantidadFloatNegativo]" @input="setPrecio(index)" v-model="detalle.porcentaje"></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field :name="'nombre['+index+']'" label="Precio S/Impuestos" type="text" 
                          @input="setPrecioConImpuesto(index)" 
                          @blur="validaPrecioVenta(index)" 
                          class="center-input" autocomplete="off" :rules="[rules.required, validaCantidadFloat, validaMaximo]"
                          v-model="detalle.precio"></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field :name="'nombre['+index+']'" label="Precio C/Impuestos" type="text" 
                        @input="setPrecioSinImpuesto(index)" 
                        @blur="validaPrecioVenta(index)" 
                        class="center-input" autocomplete="off" :rules="[rules.required, validaCantidadFloat, validaMaximo]"
                          v-model="detalle.precio_con_impuestos"></v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <v-text-field :name="'nombre['+index+']'" label="De" class="center-input" type="text" autocomplete="off" 
                            :rules="[rules.required, validaCantidadFloat, validaRango(index), validaMaximo]" v-model="detalle.de"></v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <v-text-field :name="'nombre['+index+']'" label="a" class="center-input" type="text" autocomplete="off" 
                            :rules="[rules.required, validaCantidadFloat, validaRango(index), validaMaximo]" v-model="detalle.a"></v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <!--v-btn fab dark x-small color="red" v-on:click="removePrecio(index)" title="Eliminar" v-tippy>
                          <v-icon dark>remove</v-icon>
                        </v-btn-->
                        <v-btn fab dark x-small color="blue" v-on:click="solicitudEtiqueta(detalle)" title="Crear Solicitud de Etiqueta" v-tippy>
                          <v-icon dark>mdi-cash-refund</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-container>
              </v-form>
            </fieldset>          

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="updateRegistro()">
          <v-icon>done</v-icon> Actualizar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="modal_compras" max-width="55%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Historial Compras (Últimos 12 meses)</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal_compras = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>        
            <strong> PRECIO PROMEDIO DE COMPRA: <v-btn small text :loading="loadingPrecioPromedio" title="El Precio Promedio se calcula en base al precio de compra de las existencias. Si muestra 0 es que no hay existencias positivas del producto." v-tippy>${{ parseFloat(precio_promedio_compra).toFixed(2) }}</v-btn> </strong>
            <v-client-table ref="vuetable_compras" :data="items_compras" :columns="compras.columns" :options="compras.options">
                <template slot="fecha" slot-scope="props">
                    <div style="font-size:10px !important;">{{props.row.fecha}}</div>
                </template>
                <template slot="existencia" slot-scope="props">
                    <div style="font-size:10px !important;">{{props.row.existencia}}</div>
                </template>
                <template slot="cantidad" slot-scope="props">
                    <div style="font-size:10px !important;">{{props.row.cantidad}}</div>
                </template>
                <template slot="precio" slot-scope="props">
                    <div style="font-size:10px !important;text-align:right;">{{ formatNumberDec(parseFloat(props.row.precio).toFixed(2),2)}}</div>
                </template>
                <template slot="proveedor" slot-scope="props">
                    <div class="columnaProveedor" style="font-size:10px !important;">{{props.row.proveedor}}</div>
                </template>
                
          </v-client-table>
                 

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal_compras = false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>        
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="modal_existencias" max-width="35%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Existencias Por Sucursal</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal_existencias = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>        
            <strong> EXISTENCIA TOTAL: {{ parseFloat(existencia_articulo).toFixed(2) }}</strong>
            <v-client-table ref="vuetableexistencias" :data="articulo_existencias"
                  :columns="columnsExistencias" :options="optionsExistencias">
                  <template slot="sucursal" slot-scope="props">
                      <div style="font-size:10px !important;" >{{props.row.sucursal}}</div>
                  </template>
                  <template slot="existencia" slot-scope="props">
                      <div style="font-size:10px !important;text-align:right;" >{{formatNumberDecCero(props.row.existencia,2)}}</div>
                  </template>
              </v-client-table>
                 

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal_existencias = false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>        
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- modal cantidad -->
    <v-dialog v-model="modalCantidad" max-width="500px">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Actualizar Precio</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalCantidad = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formcantidad" lazy-validation>
                    <v-container>
                        <v-row justify="center">
                            <v-col sm="12" md="12" lg="12" style="text-align:left !important;">
                                {{model_editable.nombre}}
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col sm="12" md="12" lg="12" style="text-align:left !important;">
                                {{precio_editable}}
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col sm="6" md="6" lg="6" style="text-align:center !important;">
                                <v-text-field label="Nuevo Precio" v-model="cantidad_editable" ref="ingresacantidad" v-on:keydown.enter.prevent='1' @keyup.enter="valida_guardar()" :rules="[validaCantidadFloatRequerido]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            &nbsp;
                        </v-row>
                        <v-row justify="center">
                            <v-btn @click.native="modalCantidad = false">
                                <v-icon>cancel</v-icon> Cancelar
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-btn color="success" @click.native="valida_guardar()">
                                <v-icon>done</v-icon> Aceptar
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>

</div>
</template>

<script>
import Vue from "vue";
import PaginacionComponent from '@/components/PaginacionComponent.vue';

export default {
  components: {
    "PaginacionComponent": PaginacionComponent    
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.registros.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    },
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('t.precios.ajustar.articulo')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.getTiposPrecio();
    this.get_sucursales();
    this.getProveedoresCombo();
    this.get_articulos_en_rojo();
  },
  data() {
    return {
        selectedRowIndex: null,
        id_proveedor:"", 
        proveedores_combo:[],
        loadingProveedores:false,

        articulos_en_rojo:0,
        loadingPrecioPromedio:false,
        model_etiqueta: null,
        modal_existencias:false,
        columnsExistencias: ["sucursal","existencia"],
          optionsExistencias: {
              filterable: false,
          },
        sucursales:[],
        articulo_existencias: [],
        existencia_articulo:"",
        precio_promedio_compra:0,
        modal_compras:false,
        items_compras: [],
        compras: {
                columns: [
                "fecha",                
                "cantidad",
                "precio",
                "proveedor",                
                ],
                options: {
                    filterable: false,
                    // sortable: ["fecha", "cantidad", "precio", "total"],
                    headings: {
                        fecha: "Fecha",
                        cantidad: "Cantidad",
                        precio: "Precio Proveedor",
                        "proveedor":"Proveedor",                        
                    },
                }
            },
        lakey:"1111",
        modalCantidad:false,
        cantidad_editable:"",
        model_editable: "",
        model_index: 0,
        model_row: "",
        precio_editable: "",
      TASA_IVA: 16, //Por default
      isLoading: false,
      model_filters: "",
      props_paginacion: {
        infiniteHandler: this.infiniteHandler,
        total_registros: 0
      },
      articulos: [],
      tipos_precios: [],
      fab: [],
      name: "datagrid",
      columns: [
        "codigo_barras",
        "nombre",
        "descripcion",
        "categoria",        
        "existencia",
        "precio_compra_con_iva",
        "precio_compra_promedio",    
        "publico", "diferencia_precio_publico","utilidad_publico",
        "mayoreo", "diferencia_precio_mayoreo","utilidad_mayoreo",
        "mayoreo2", "diferencia_precio_mayoreo2","utilidad_mayoreo2",
        "actions"
      ],
      show_loading: true,
      options: {
        rowClassCallback: this.getRowClass,
        filterable: ["codigo_barras", "nombre", "descripcion", "categoria", "existencia","precio_compra_con_iva"],
        sortable: ["codigo_barras", "nombre", "descripcion", "categoria", "existencia","precio_compra_con_iva"],

        headings: {
          "codigo_barras": "Código de Barras",
          "descripcion": "Descripción",
          "categoria": "Categoría",          
          "existencia":"Existencia",
          "precio_compra_con_iva": "Ultimo Precio Compra",  
          "precio_compra_promedio": "Precio Compra Promedio",  
          "diferencia_precio_publico":"$ Utilidad",
          "diferencia_precio_mayoreo":"$ Utilidad",
          "diferencia_precio_mayoreo2":"$ Utilidad",
          "utilidad_publico":"Utilidad %",
          "utilidad_mayoreo":"Utilidad %",
          "utilidad_mayoreo2":"Utilidad %",        
          actions: "Operaciones"
        },
      },
      columns_proveedor: [
        "tipo_articulo",
        "codigo_barras",
        "nombre",
        "descripcion",
        "categoria",        
        "existencia",
        "precio_compra_con_iva",
        "precio_compra_promedio",    
        "publico", "diferencia_precio_publico","utilidad_publico",
        "mayoreo", "diferencia_precio_mayoreo","utilidad_mayoreo",
        "mayoreo2", "diferencia_precio_mayoreo2","utilidad_mayoreo2",
        "actions"
      ],      
      options_proveedor: {
        rowClassCallback: this.getRowClass,
        perPage: 10,
        pagination: {
            show: true
        },
        filterable: ["tipo_articulo","codigo_barras", "nombre", "descripcion", "categoria", "existencia","precio_compra_con_iva"],
        sortable: ["tipo_articulo","codigo_barras", "nombre", "descripcion", "categoria", "existencia","precio_compra_con_iva"],

        headings: {
          "tipo_articulo":"Tipo",
          "codigo_barras": "Código de Barras",
          "descripcion": "Descripción",
          "categoria": "Categoría",          
          "existencia":"Existencia",
          "precio_compra_con_iva": "Ultimo Precio Compra",  
          "precio_compra_promedio": "Precio Compra Promedio",  
          "diferencia_precio_publico":"$ Utilidad",
          "diferencia_precio_mayoreo":"$ Utilidad",
          "diferencia_precio_mayoreo2":"$ Utilidad",
          "utilidad_publico":"Utilidad %",
          "utilidad_mayoreo":"Utilidad %",
          "utilidad_mayoreo2":"Utilidad %",        
          actions: "Operaciones"
        },
      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Precios",
          disabled: true,
          href: ""
        },
        {
          text: "Ajuste de Precios por Artículo",
          href: ""
        }
      ],
        model: {
        _id: "",
        _rev: "",
        type: "articulos",
        codigo_barras: [],
        nombre: "",
        created_at: "",
        estatus: "Activo",
        descripcion: "",
        categoria: "",
        caducidad: "",
        tiene_impuestos: "",
        tiene_grupo: "",
        tiene_informacion: "",
        marca: "",
        precios_venta: [],
        impuestos: [],
        grupo_articulos: [],
        informacion_nutrimental: [],
        producto_servicio: "",
        unidad_medida: "",
        precio_compra: "",
        precio_compra_con_iva: "",
        //nuevos
        equivalencias: [],
        proveedores: []        
      },
      modal: false,
      update: false,      
      rules: {
        required: v => !!v || "Este campo es requerido",
      },            
      registros: {
        selected: [],
        items: [],
        proveedor: [],        
      }, 
      model_index: 0,
        model_row: "",

    };
  },

  methods: {
    infiniteHandler: function($state) {
      var rows_per_page = 10;
      var self = this;
      var page = Math.ceil(this.registros.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      let where = {
        "type": "articulos",
        "estatus":"Activo"
      };

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          if (x == "codigo_barras") {
            where[x] = {
              "$elemMatch": {
                "$regex": "(?i)" + this.model_filters[x]
              }
            }
          } else if (x == "categoria") {
            where[x] = {
              "$elemMatch": {
                "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
              }
            }
          } else if (x == "precio_compra_con_iva") {
             //where[x] = parseFloat(this.model_filters[x])
            where["$or"] = [
                {"precio_compra_con_iva": parseFloat(this.model_filters[x])}, {"precio_compra_con_iva": {"$regex": "(?i)" + this.model_filters[x]}}
              ]
          } else {
            where[x] = {
              "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
            }
          }
        }
      });


        axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where
          },
        }).then(async response => {
          if (response.data.docs.length) {
            await Promise.all(response.data.docs.map(async (x) => {  
              
                    let datos_consulta = {
                        "id_articulo": x._id,                        
                    };

                    await self.calcula_precio_promedio_row(x._id).then(async precioPromedio => {
                        x["precio_compra_promedio"] = precioPromedio;                        
                    }).catch(err => {
                        console.log(err);
                        console.log("Ocurrió un error al obtener precio promedio de compra de artículo: ", x._id);
                    });

                    await window.funciones_lotes.consultaExistencia(datos_consulta).then(async result => {
                        x["existencia"] = 0;
                        if (result.length > 0) {
                            result.forEach(el => {
                                x["existencia"] += el.existencia;
                            });
                        }                                                                     
                    }).catch(err => {
                        console.log("Ocurrió un error al consultar existencia de artículo: ", x._id);
                    });

                    if (x.precio_proveedor == undefined || x.precio_proveedor == "")
                        x.precio_proveedor = 0.00;

                    x.precio_proveedor = parseFloat(x.precio_compra_con_iva).toFixed(2);
                    
                    if (x.precios_venta != undefined && x.precios_venta.length > 0) {
                        x.publico = "N/A";
                        x.mayoreo = "N/A";
                        x.mayoreo2 = "N/A";
                        x.precios_venta.forEach(function (y) {
                            //console.log("Precios: ", x);
                            if (y.tipo_precio == "Público General") {
                                if(y.precio_con_impuestos != undefined)
                                    x.publico = parseFloat(y.precio_con_impuestos).toFixed(2);
                                else
                                    x.publico = parseFloat(0.00).toFixed(2);
                            }
                            if (y.tipo_precio == "Mayoreo") {
                                if(y.precio_con_impuestos != undefined)
                                    x.mayoreo = parseFloat(y.precio_con_impuestos).toFixed(2);
                                else
                                    x.mayoreo = parseFloat(0.00).toFixed(2);
                            }
                            if (y.tipo_precio == "Mayoreo 2") {
                                if(y.precio_con_impuestos != undefined)
                                    x.mayoreo2 = parseFloat(y.precio_con_impuestos).toFixed(2);
                                else 
                                    x.mayoreo2 = parseFloat(0.00).toFixed(2);
                            }
                        })
                    }                                        
            }));
            //buscar items nuevos, para que no se dupliquen
            var unique_records = new Set(this.registros.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
            
            this.registros.items = this.registros.items.concat(nuevos);
            this.props_paginacion.total_registros = this.registros.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    get_articulos_en_rojo: function(){
      window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/precios_rojos/')
        .then(responseArticulo => {
            if(responseArticulo.data != null && responseArticulo.data.rows!=null && responseArticulo.data.rows.length>0){
                this.articulos_en_rojo = responseArticulo.data.rows.length;
            } else {
                this.articulos_en_rojo = 0;
            }            
        })
        .catch(error => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al obtener los articulos en rojo",
                footer: error
            });
        });
    },
    validarRangoNoRepetido: function (rangoExterior, rangoInterior) {
        // Verifica que el rango exterior no esté dentro del rango interior
        if (rangoExterior[0] >= rangoInterior[0] && rangoExterior[1] <= rangoInterior[1]) {
            return false;
        }

        // Verifica que el rango interior no esté dentro del rango exterior
        if (rangoInterior[0] >= rangoExterior[0] && rangoInterior[1] <= rangoExterior[1]) {
            return false;
        }

        // Verifica que los rangos no se solapen
        if (rangoExterior[0] >= rangoInterior[0] && rangoExterior[0] <= rangoInterior[1]) {
            return false;
        }

        if (rangoExterior[1] >= rangoInterior[0] && rangoExterior[1] <= rangoInterior[1]) {
            return false;
        }

        return true;
    },
    validaRango: function(index){
        var record1 = this.model.precios_venta[index];
        for(var krango in this.model.precios_venta){
            if(krango != index){
                var record2 = this.model.precios_venta[krango];
                var rango1 = [parseFloat(record1.de), parseFloat(record1.a)];
                var rango2 = [parseFloat(record2.de), parseFloat(record2.a)];
                if (this.validarRangoNoRepetido(rango1, rango2)) {
                    //console.log('El rango exterior'+index+' no se repite dentro del rango interior'+krango);
                } else {
                    //console.log('El rango exterior'+index+' se repite dentro del rango interior'+krango);
                    return "El rango no debe repetirse o estar dentro de otro.";
                }
            }
        }
        return true;
    },
   
    getTiposPrecio: function() {
      let data = {
        "selector": {
          "type": "configuracion",
        },

        "fields": ["_id", "tipos_precio"]
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0)
            this.tipos_precios = response.data.docs[0].tipos_precio != undefined ? response.data.docs[0].tipos_precio : [];
          else
            this.tipos_precios = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los tipos de precios.",
            footer: ""
          });
        });
    },
    getRegistro: function(id_registro) {

      window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id_registro)
        .then(response => {
          try {
            this.model = response.data;
            this.fechaUsuario = window.moment(this.model.created_at).format("DD-MM-YYYY");
            if (this.model.informacion_nutrimental == null) {
              this.model.informacion_nutrimental = [];
              this.model.tiene_informacion = 0;
            }            
            this.update = true;
            this.modal = true;
          } catch (err) {
            console.log("ERROR: ", err);
          }

        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener el registro",
            footer: ""
          });
        });
    },   
    excel_precios_rojos: function(){

        window.dialogLoader.show('Espere un momento por favor..');

        window.axios
            .post(process.env.VUE_APP_REPORTES_URL + "/reportes/rpt_precios_rojos/", {}, {              
                "Content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Allow": "POST",
                "cache-control": "no-cache",
                "responseType": "arraybuffer"
            })
            .then(response => {
                var bytes = response.data;
                let blob = new Blob([bytes], {
                        type: response.headers["content-type"]
                    }),
                    url = window.URL.createObjectURL(blob);

                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = url;
                a.download = "PreciosEnRojo.xlsx";
                a.click();
                a.parentNode.removeChild(a);
            })
            .catch(error => {
              console.log(error);
              this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al generar el reporte. Intente nuevamente.",
                  footer: ""
              });
            }).then(()=>{
              window.dialogLoader.hide();
            });
    },
    getCompras: async function(id_articulo_calculo) {
        try {
          var self = this;
            window.dialogLoader.show('Espere un momento por favor..'); 
            this.items_compras = [];     
            self.calcula_precio_promedio(id_articulo_calculo);       

            var today = window.moment().format("YYYY-MM-DD");
            var fecha_de_venta = window.moment().subtract(12, 'months').format("YYYY-MM-DD");
            var fecha_a_venta = today;

            let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/compras/_view/compras_grupo';
            const [year_de, month_de, day_de] = fecha_de_venta.split("-");
            const [year_a, month_a, day_a] = fecha_a_venta.split("-");
            let urlConditions = `?startkey=["${id_articulo_calculo}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${id_articulo_calculo}", "${year_a}", "${month_a}", "${day_a}"]`;

            axios.get(url + urlConditions)
            .then(response => {
                if (response.data.rows.length > 0) {
                    let respuesta = response.data.rows;
                    let procesado = [];                    

                    //Aqui ya seria agrupar por dia, semana o mes
                    //console.log("busqueda por dias")
                    respuesta.forEach(x => {
                        let key = `${x.key[1]}-${x.key[2]}-${x.key[3]}`;

                        if(x.value.precio_proveedor.toString().trim() != ""){
                            procesado.push({
                                "fecha": key,
                                "cantidad": x.value.cantidad,
                                "existencia": x.value.existencia,
                                "precio": x.value.precio_proveedor,
                                "proveedor": x.value.proveedor.nombre_proveedor,
                                "total": 0
                            });                            
                        }
                    });
                    //console.log(procesado);

                    //Poner arreglo en reversa para mostrar la compra mas reciente al inicio
                    this.items_compras = procesado.reverse();
                } else {
                    this.items_compras = [];
                }
            })
            .catch(error => {
                this.items_compras = [];
                console.log(error)
            }).then(()=>{
                this.modal_compras = true;
                window.dialogLoader.hide();                
            });
        } catch (error) {
            console.log("Error al consultar compras: ", error);
        }
    },
    calcula_precio_promedio: async function(id_articulo_calculo){
        var self = this;
        this.precio_promedio_compra = 0;
        self.loadingPrecioPromedio = true;
        
        var precio_sumatoria = 0;
        var cantidad_lotes = 0;

        for(var k in this.sucursales){
            var id_sucursal = this.sucursales[k]._id;
            let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/lotes/_view/lotes_articulo';
              let urlConditions = `?startkey=["${id_sucursal}","${id_articulo_calculo}"]&endkey=["${id_sucursal}","${id_articulo_calculo}"]`;
              await axios.get(url + urlConditions)
              .then(response => {
                  if (response.data.rows.length > 0) {
                    let lotes = response.data.rows;                  
                    //console.log("LOTES: ", lotes);
                    for(var kl in lotes){
                      if( parseFloat(lotes[kl].value.precio_compra) > 0){
                        precio_sumatoria += parseFloat(lotes[kl].value.precio_compra);
                        cantidad_lotes += 1;
                      }
                    }
                   
              } 
            }).catch(error => {
              
            });
        }

        if(cantidad_lotes > 0){
          self.precio_promedio_compra = precio_sumatoria > 0 ? precio_sumatoria / cantidad_lotes : 0;
        }
        self.loadingPrecioPromedio = false;
        
    },
    //Este metodo es con Promise para regresarlo en las filas
    calcula_precio_promedio_row: async function(id_articulo_calculo){
      var self = this;
      var precio_promedio_compra = 0;
      var precio_sumatoria = 0;
      var cantidad_lotes = 0;
      return new Promise(async function(resolve, reject) {
          try{
              if(self.sucursales.length == 0){
                self.registros.items = [];
                self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                return resolve(0);
              }
              for(var k in self.sucursales){
                var id_sucursal = self.sucursales[k]._id;
                let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/lotes/_view/lotes_articulo';
                  let urlConditions = `?startkey=["${id_sucursal}","${id_articulo_calculo}"]&endkey=["${id_sucursal}","${id_articulo_calculo}"]`;
                  await axios.get(url + urlConditions)
                  .then(response => {
                      if (response.data.rows.length > 0) {
                        let lotes = response.data.rows;                  
                        //console.log("LOTES: ", lotes);
                        for(var kl in lotes){
                          if( parseFloat(lotes[kl].value.precio_compra) > 0){
                            precio_sumatoria += parseFloat(lotes[kl].value.precio_compra);
                            cantidad_lotes += 1;
                          }
                        }
                      
                  } 
                }).catch(error => {
                  
                });
            }

            if(cantidad_lotes > 0){
              precio_promedio_compra = precio_sumatoria > 0 ? precio_sumatoria / cantidad_lotes : 0;
            }
            return resolve(precio_promedio_compra);

          } catch(e){
              return reject(e);
          }

      });
        
    },
    updateRegistro: function() {

      if (this.$refs.form.validate() && this.$refs.formprecios.validate() &&
        ((this.$refs.formimpuestos != null && this.$refs.formimpuestos.validate()) || this.$refs.formimpuestos == null)
      ) {

        window.dialogLoader.show('Espere un momento por favor..');        

          let data = this.model;
          data["_id"] = this.model._id;
          data["_rev"] = this.model._rev;

          if (data["precios_venta"].length == 0) {
            window.dialogLoader.hide();
            this.$swal({
              type: "info",
              title: "¡Datos Incompletos!",
              text: "Debe agregar al menos un precio de venta. Favor de verificar.",
              footer: ""
            });
            return true;
          }

          var flag_tipo = false;
          var flag_precio = false;
          var orden = [];
          if(data["precios_venta"].length > 1) {
            data["precios_venta"].forEach((it) => {
              it.de = parseFloat(it.de);
              it.a = parseFloat(it.a);
              let existe = this.tipos_precios.filter(x => x.nombre == it.tipo_precio);
              if(existe.length > 0) {
                existe = existe[0].orden;
                orden.push({"orden": existe, "de": it.de, "a": it.a, "precio": it.precio_con_impuestos})
              } else {
                console.log("ya no existe un tipo de precio");
              }
            });

            orden = orden.sort((a,b) => (a.orden < b.orden) ? -1 : (a.orden > b.orden) ? 1 : 0);
            let tmp=null;
            orden.forEach((or) => {
              if(tmp == null) {
                tmp = or.a;
              } else {
                if(tmp >= or.de) {
                  flag_tipo = true;
                } else {
                  tmp = or.a;
                }
              }
            });

            let tmp2=null;
            orden.forEach((or) => {
              if(tmp2 == null) {
                tmp2 = or.precio;
              } else {
                if(tmp2 < or.precio) {
                  console.log(tmp2, or.precio);
                  flag_precio = true;
                } else {
                  tmp2 = or.precio;
                }
              }
            });


          } else {
            data["precios_venta"].forEach((it) => { //Convertimos a float 
              it.de = parseFloat(it.de);
              it.a = parseFloat(it.a);              
            });
          }
          if (flag_tipo) {
            window.dialogLoader.hide();
            this.$swal({
              icon: "info",
              title: "¡Datos Incompletos!",
              text: "Error en los tipos de precios. De o A no pueden ser iguales o menores a los tipos de precio con orden inferior.",
              footer: ""
            });
            return true;
          }
          if (flag_precio) {
            window.dialogLoader.hide();
            this.$swal({
              icon: "info",
              title: "¡Datos Incompletos!",
              text: "Error en los tipos de precios. El Precio de los tipos de precio no puede ser menor a un tipo de precio con orden mayor.",
              footer: ""
            });
            return true;
          }
         
          window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', data)
            .then(response => {
              window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                color: 'success'
              });
              this.registros.items = [];
              this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            })
            .catch(error => {
              window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
                color: 'error'
              });
            }).then(() => {
              this.modal = false;
              window.dialogLoader.hide();
            });
       
      }
    },
    setPrecio: function(index) {
      var impuestos = 0;
      this.model.precios_venta[index].porcentaje = parseFloat(this.model.precios_venta[index].porcentaje);
      var porciento = this.model.precios_venta[index].porcentaje;
      var precio_compra = this.model.precio_compra;
      //if(porciento != "" && precio_compra!=null && precio_compra!="" && parseFloat(porciento) >= 0 ){
      if (this.validaCantidadFloat(porciento) === true && this.validaCantidadFloat(precio_compra) === true) {
        this.model.precios_venta[index].precio = parseFloat(precio_compra) * (1 + (parseFloat(porciento) / 100));
        this.model.precios_venta[index].precio = parseFloat(this.model.precios_venta[index].precio.toFixed(2));
        for (var k in this.model.impuestos) {
          impuestos += parseFloat(this.model.impuestos[k]["tasa"]);
        }
        this.model.precios_venta[index].precio_con_impuestos = parseFloat(this.model.precios_venta[index].precio) * (1 + (parseFloat(impuestos) / 100));
        this.model.precios_venta[index].precio_con_impuestos = parseFloat(this.model.precios_venta[index].precio_con_impuestos.toFixed(2));        
        //}
      } else {
        this.model.precios_venta[index].precio = "";
      }
    },
    addNewPrecio: function() {
      this.model.precios_venta.push(Vue.util.extend({}, []));
    },
    removePrecio: function(index) {
      Vue.delete(this.model.precios_venta, index);
    },
    validaTipoPrecio: function(val) {
      if (val != null && val != "") {
        var precios = this.model.precios_venta.filter(e => e.tipo_precio == val);
        if (precios.length >= 2)
          return "No debe repetir el tipo de precio";
      }
      return true;
    },
    validaCantidadFloatRequerido: function (value) {
        const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
        if (value != null && value != "" && !pattern.test(value)) {
            return "Cantidad no válida"
        } else if (value != null && value != "") {
            if (parseFloat(value) <= 0)
                return "Debe ser mayor que cero";
        } else if (value != null && value == "")
            return "Campo requerido";
        return true;
    },
    validaMaximo: function(value) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && pattern.test(value)) {
        if(parseFloat(value) > 100000)
          return "Cantidad demasiado grande";
      } 
      return true;

    },
    validaCantidadFloat: function(value) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && !pattern.test(value)) {
        return "Cantidad no válida"
      } else
        return true;

    },
    validaCantidadFloatNegativo: function(value) {
      const pattern = /^[\-]{0,1}[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && !pattern.test(value)) {
        return "Cantidad no válida"
      } else
        return true;

    },
    setPrecioConImpuesto: function(index) {
      //Se calcula el precio con impuesto y el porcentaje
      var porciento = 0;
      this.model.precios_venta[index].precio = parseFloat(this.model.precios_venta[index].precio);
      var precio_venta = this.model.precios_venta[index].precio;
      var precio_compra = this.model.precio_compra;
      if (precio_compra != null && precio_compra != "" && this.validaCantidadFloat(precio_compra) === true) {
        if (precio_venta != null && precio_venta != "") {
          if (this.validaCantidadFloat(precio_venta) === true) {
            for (var k in this.model.impuestos) {
              porciento += parseFloat(this.model.impuestos[k]["tasa"]);
            }
            this.model.precios_venta[index].precio_con_impuestos = parseFloat(precio_venta) * (1 + (parseFloat(porciento) / 100));
            this.model.precios_venta[index].precio_con_impuestos = parseFloat(this.model.precios_venta[index].precio_con_impuestos.toFixed(2));
            this.model.precios_venta[index].porcentaje = ((precio_venta / precio_compra) - 1) * 100;
            this.model.precios_venta[index].porcentaje = parseFloat(this.model.precios_venta[index].porcentaje.toFixed(2));            
          }
        } else {
          this.model.precios_venta[index].precio_con_impuestos = "";
        }

      } else {
        this.$swal({
          type: "error",
          title: "Ingrese Precio de Compra",
          text: "Debe ingresar el precio de compra.",
          footer: ""
        }).then(() => {
          setTimeout(() => {
            this.$nextTick(() => this.$refs.precio_compra_ref.focus());
          }, 100);
        });
      }

    },
    setPrecioSinImpuesto: function(index) {
      //Se calcula el precio sin impuesto y el porcentaje
      var porciento = 0;
      this.model.precios_venta[index].precio_con_impuestos = parseFloat(this.model.precios_venta[index].precio_con_impuestos);
      var precio_venta = this.model.precios_venta[index].precio_con_impuestos;
      var precio_compra = this.model.precio_compra;
      if (precio_compra != null && precio_compra != "" && this.validaCantidadFloat(precio_compra) === true) {
        if (precio_venta != null && precio_venta != "") {
          if (this.validaCantidadFloat(precio_venta) === true) {
            for (var k in this.model.impuestos) {
              porciento += parseFloat(this.model.impuestos[k]["tasa"]);
            }
            this.model.precios_venta[index].precio = parseFloat(precio_venta) / (1 + (parseFloat(porciento) / 100));
            this.model.precios_venta[index].precio = parseFloat(this.model.precios_venta[index].precio.toFixed(2));
            this.model.precios_venta[index].porcentaje = ((this.model.precios_venta[index].precio / precio_compra) - 1) * 100;
            this.model.precios_venta[index].porcentaje = parseFloat(this.model.precios_venta[index].porcentaje.toFixed(2));            
          }
        } else {
          this.model.precios_venta[index].precio = "";
        }

      } else {
        this.$swal({
          type: "error",
          title: "Ingrese Precio de Compra",
          text: "Debe ingresar el precio de compra.",
          footer: ""
        }).then(() => {
          this.$nextTick(() => this.$refs.precio_compra_ref.focus());
        });
      }
    },
    validaPrecioVenta: function(index){
      var precio_venta = this.model.precios_venta[index].precio_con_impuestos;
      var precio_compra = this.model.precio_compra_con_iva;
      
      if (precio_compra != null && precio_compra != "" && this.validaCantidadFloat(precio_compra) === true) {
        if (precio_venta != null && precio_venta != "" && this.validaCantidadFloat(precio_venta) === true) {
          if (parseFloat(precio_compra) >= parseFloat(precio_venta)) {
            this.$swal({
              type: "warning",
              title: "!Cuidado¡",
              text: "El precio de compra es menor o igual al precio de venta. Favor de verificar.",
              footer: ""
            });
          }
        }
      }      
    },
    solicitudEtiqueta: function(detalle) {
      var self = this;
      
      if(this.model_etiqueta != null){//Si ya se habia creado una solicitud, agregamos ahi los articulos que vayan cambiando de precio

        window.axios
          .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model_etiqueta._id)
          .then(response => {
              this.model_etiqueta = response.data;

              this.model_etiqueta.estatus = "Pendiente";
      
              this.model_etiqueta.articulos.push({
                  id_articulo: self.model._id,
                  nombre_articulo: self.model.nombre,
                  descripcion: self.model.descripcion,
                  codigo_articulo: self.model.codigo_barras,
                  cantidad: 1,
                  precio: parseFloat(detalle.precio_con_impuestos != undefined ? detalle.precio_con_impuestos : detalle.precio),
                  tipo_precio: detalle.tipo_precio,
                  mayoreo: detalle.de
              });
              window.axios
                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_etiqueta._id + '?conflicts=true', this.model_etiqueta)
                .then(response => {
                    this.model_etiqueta._rev = response.data.rev;  
                    window.dialogLoader.showSnackbar('Solicitud Enviada', {
                    color: 'success'
                  });            
                })
                .catch(error => {
                    window.dialogLoader.showSnackbar('Ocurrió un error al crear la solicitud. Intente nuevamente', {
                    color: 'error'
                  });
                });
          })
          .catch(error => {
              window.dialogLoader.showSnackbar('Ocurrió un error al crear la solicitud. Intente Nuevamente', {
                  color: 'error'
                });
          });
        
      } else {
        var etq = {
          type: "etiquetas",
          fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
          estatus: "Pendiente",
          articulos: [{
            id_articulo: self.model._id,
            nombre_articulo: self.model.nombre,
            descripcion: self.model.descripcion,
            codigo_articulo: self.model.codigo_barras,
            cantidad: 1,
            precio: parseFloat(detalle.precio_con_impuestos != undefined ? detalle.precio_con_impuestos : detalle.precio),
            tipo_precio: detalle.tipo_precio,
            mayoreo: detalle.de
          }],
          usuario: self.$local_storage.get("sb_usuario"),
          observaciones: "Ajuste Precio por Artículo",
        };
        this.model_etiqueta = etq;

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', etq)
          .then(response => {
            //console.log("Response", response);
            this.model_etiqueta["_rev"] = response.data.rev;
            this.model_etiqueta["_id"] = response.data.id;
            window.dialogLoader.showSnackbar('Solicitud Enviada', {
              color: 'success'
            });
          })
          .catch(error => {
            console.log(error);
            window.dialogLoader.showSnackbar('Ocurrió un error al crear la solicitud. Intente nuevamente.', {
              color: 'error'
            });
          });
      }
      

    },
    cambiar: async function (row, tipo, index) {
            this.model_row = row;
            this.model_index = index - 1;
            this.cantidad_editable = "";
            if(tipo==1)
                this.cantidad_editable = row.publico;
            else if(tipo==2)
                this.cantidad_editable = row.mayoreo;
            else if(tipo==3)
                this.cantidad_editable = row.mayoreo2;

            await window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
                .then(response => {

                    this.model_editable = response.data;
                    this.modalCantidad = true;

                    if (tipo == 1) {
                        this.precio_editable = "Público General";
                    }
                    else if (tipo == 2) {
                        this.precio_editable = "Mayoreo";
                    }
                    else if (tipo == 3) {
                        this.precio_editable = "Mayoreo 2";
                    }

                    setTimeout(() => {
                        this.$nextTick(() => this.$refs.ingresacantidad.focus());
                    }, 100);

                })
                .catch(error => {
                    console.log(error);
                    window.dialogLoader.hide();
                });

        },
        get_sucursales: function () {            
            let data = {
                "selector": {
                    "nombre":{"$exists":true}
                },
                "fields": [
                    "nombre", "_id","abastos","matriz"
                ],
                "use_index": "_design/84f098ad708a26e38d78e9daf0da60ca49fd02ea"
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    this.sucursales = [];
                    if (response.data.docs.length > 0){
                        this.sucursales = response.data.docs;                        
                    }                                      
                }).catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener las sucursales.",
                        footer: ""
                    });
                });
        },
        getNombreSucursal: function(id_sucursal) {
            var filter = this.sucursales.find(e => e._id == id_sucursal);
            if (filter)
                return filter.nombre;
            else
                return "";
        },
        formatNumberDec: function(numero, decimales){
            if (numero == undefined || numero == null || numero == "")
                numero = 0;
            numero = Number(numero);
            if (isNaN(numero)){
                return "";
            } else
                return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        },
        formatNumberDecCero: function(numero, decimales){
            if (numero == undefined || numero == null || numero == "")
                numero = 0;
            numero = Number(numero);
            if (isNaN(numero)){
                return "";
            } else{
              if(parseFloat(numero) == parseInt(numero))
                return numero.toString().replace(/\d(?=(\d{3}))/g, '$&,');
              else
                return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            }
                
        },
        ver_existencias: async function(row) {
            let n = 0;
            var self = this;
            await window.funciones_lotes.consultaExistencia({
                "id_articulo": row._id
            }).then(result => {
                if (result.length > 0) {

                    result.forEach(el => {
                        n += el.existencia
                        el["sucursal"] = self.getNombreSucursal(el.id_sucursal);
                    });
                    this.articulo_existencias = result;
                    this.existencia_articulo = n;
                } else {
                    this.existencia_articulo = 0;
                    this.articulo_existencias = [];
                }
                this.modal_existencias = true;
                //console.log("se asigno la existencia");

            }).catch(err => {
                console.log("error", err);
                this.existencia_articulo = 0;
                this.articulo_existencias = [];
            });
        },
        valida_guardar: function(){

            if(parseFloat(this.model_row.precio_proveedor) > parseFloat(this.cantidad_editable)){
                this.$swal({
                    type: "info",
                    title: "Cuidado",
                    text: "El precio de venta es menor al precio de compra ¿Aún asi desea continuar?",
                    footer: "",
                    showCancelButton: true,
                    cancelButtonColor: "#d33",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Si",
                    cancelButtonText: "No"
                }).then(resultado => {
                    if (resultado.value) {
                        this.guardar();
                    } else {
                        this.modalCantidad = false;
                    }
                });
            } else {
                this.guardar();
            }            
        },
        guardar: function () {
            let self = this;
            let encontrado = false;

            //validar que el precio de Mayore 2, no sea mayor a Mayoreo, y Mayoreo no sea mayor a Público
            if(this.precio_editable == "Mayoreo 2" && this.cantidad_editable.toString()!='N/A' && parseFloat(this.cantidad_editable) > 0){
                if(this.model_row.mayoreo.toString()!='N/A' && parseFloat(this.model_row.mayoreo) > 0){
                    if(parseFloat(this.cantidad_editable) > parseFloat(this.model_row.mayoreo)){
                        this.$swal({
                            type: "warning",
                            title: "Cuidado",
                            text: "El precio de Mayoreo 2 es mayor a Mayoreo. Favor de verificar.",
                            footer: "",                            
                        });
                        return;
                    }
                }
                if(this.model_row.publico.toString()!='N/A' && parseFloat(this.model_row.publico) > 0){
                    if(parseFloat(this.cantidad_editable) > parseFloat(this.model_row.publico)){
                        this.$swal({
                            type: "warning",
                            title: "Cuidado",
                            text: "El precio de Mayoreo 2 es mayor a Público General. Favor de verificar.",
                            footer: "",                            
                        });
                        return;
                    }
                }
            }else if(this.precio_editable == "Mayoreo" && this.cantidad_editable.toString()!='N/A' && parseFloat(this.cantidad_editable) > 0){
                if(this.model_row.publico.toString()!='N/A' && parseFloat(this.model_row.publico) > 0){
                    if(parseFloat(this.cantidad_editable) > parseFloat(this.model_row.publico)){
                        this.$swal({
                            type: "warning",
                            title: "Cuidado",
                            text: "El precio de Mayoreo es mayor a Público General. Favor de verificar.",
                            footer: "",                            
                        });
                        return;
                    }
                }
            }else if(this.precio_editable == "Público General" && this.cantidad_editable.toString()!='N/A' && parseFloat(this.cantidad_editable) > 0){
                if(this.model_row.mayoreo2.toString()!='N/A' && parseFloat(this.model_row.mayoreo2) > 0){
                    if(parseFloat(this.cantidad_editable) < parseFloat(this.model_row.mayoreo2)){
                        this.$swal({
                            type: "warning",
                            title: "Cuidado",
                            text: "El precio Públigo General es menor a Mayoreo 2. Favor de verificar.",
                            footer: "",                            
                        });
                        return;
                    }
                }
                if(this.model_row.mayoreo.toString()!='N/A' && parseFloat(this.model_row.mayoreo) > 0){
                    if(parseFloat(this.cantidad_editable) < parseFloat(this.model_row.mayoreo)){
                        this.$swal({
                            type: "warning",
                            title: "Cuidado",
                            text: "El precio Públigo General es menor a Mayoreo. Favor de verificar.",
                            footer: "",                            
                        });
                        return;
                    }
                }
            }
            
            //console.log("ID: ", this.model_row._id);
            //console.log("Tipo: ", this.precio_editable);
            //console.log("Precio: ", this.cantidad_editable);
            var tipos = {"Mayoreo 2":"mayoreo2","Mayoreo":"mayoreo","Público General":"publico"}

            let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE
            + "/_design/funciones_articulos/_update/actualizar_precio_venta/" + this.model_row._id;

                var data = {
                    tipo_precio: this.precio_editable,
                    precio_con_impuestos: parseFloat(this.cantidad_editable),
                };

                window.axios
                .post(url, data)
                .then(response => {
                    if (response.data == true) {
                        window.dialogLoader.showSnackbar('El precio se actualizó correctamente..', {
                            color: 'success'
                        });
                        this.modalCantidad = false;
                        this.solicitudEtiquetaCambio();
                        //self.registros.items[self.model_index][tipos[this.precio_editable]] = this.cantidad_editable;
                        if(this.id_proveedor!=null && this.id_proveedor!=undefined && this.id_proveedor!=""){//Si tiene proveedor, esta editando articulos filtrados por proveedor
                          let articulo = self.registros.proveedor.find(e => e._id == self.model_row._id);                          
                          if( articulo){
                            articulo[tipos[this.precio_editable]] = self.cantidad_editable;
                          }
                        } else {
                          let articulo = self.registros.items.find(e => e._id == self.model_row._id);                          
                          if( articulo){
                            articulo[tipos[this.precio_editable]] = self.cantidad_editable;
                          }
                        }
                        
                        
                    } else {
                        console.log("Precio No Actualizado");
                    }
                })
                .catch(error => {
                    console.log("Error: ",error);
                    window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
                        color: 'error'
                    });
                }).then(() => {
                    this.modal = false;
                    window.dialogLoader.hide();
                });            

        },
        solicitudEtiquetaCambio: function () {
            var self = this;

            if(this.model_etiqueta != null){//Si ya se habia creado una solicitud, agregamos ahi los articulos que vayan cambiando de precio

              //Obtenemos versión actual
              window.axios
              .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model_etiqueta._id)
              .then(response => {
                  this.model_etiqueta = response.data;

                  this.model_etiqueta.estatus = "Pendiente";

                  var cantidad_mayoreo = 0;
                  if(self.precio_editable == "Mayoreo"){
                      self.model_editable.precios_venta.forEach(function(e){
                          if(e.tipo_precio == "Mayoreo")
                              cantidad_mayoreo = e.de;
                      });
                  }

                  this.model_etiqueta.articulos.push({
                        id_articulo: self.model_editable._id,
                        nombre_articulo: self.model_editable.nombre,
                        descripcion: self.model_editable.descripcion,
                        codigo_articulo: self.model_editable.codigo_barras,
                        cantidad: 1,
                        precio: parseFloat(self.cantidad_editable),
                        tipo_precio: self.precio_editable,
                        mayoreo: cantidad_mayoreo
                  });
                  window.axios
                    .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_etiqueta._id + '?conflicts=true', this.model_etiqueta)
                    .then(response => {
                        this.model_etiqueta._rev = response.data.rev;  
                        window.dialogLoader.showSnackbar('Solicitud Enviada', {
                        color: 'success'
                      });            
                    })
                    .catch(error => {
                        window.dialogLoader.showSnackbar('Ocurrió un error al crear la solicitud. Intente nuevamente', {
                          color: 'error'
                        });
                    });
              })
              .catch(error => {
                  if (error.response.data != null && error.response.data.error != null && error.response.data.reason != null) {
                    console.log(error.response.data);
                    if (error.response.data.error == "not_found" && error.response.data.reason == "deleted") {
                      this.model_etiqueta = null;
                      this.solicitudEtiquetaCambio();
                    } else {
                        window.dialogLoader.showSnackbar('Ocurrió un error al crear la solicitud. Intente Nuevamente', {
                          color: 'error'
                        });
                    }
                  } else {
                      window.dialogLoader.showSnackbar('Ocurrió un error al crear la solicitud. Intente Nuevamente', {
                        color: 'error'
                      });
                  }                  
              });
              
            } else {

              var cantidad_mayoreo = 0;
              if(self.precio_editable == "Mayoreo"){
                  self.model_editable.precios_venta.forEach(function(e){
                      if(e.tipo_precio == "Mayoreo")
                          cantidad_mayoreo = e.de;
                  });
              }
              var etq = {
                  type: "etiquetas",
                  fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                  estatus: "Pendiente",
                  articulos: [{
                      id_articulo: self.model_editable._id,
                      nombre_articulo: self.model_editable.nombre,
                      descripcion: self.model_editable.descripcion,
                      codigo_articulo: self.model_editable.codigo_barras,
                      cantidad: 1,
                      precio: parseFloat(self.cantidad_editable),
                      tipo_precio: self.precio_editable,
                      mayoreo: cantidad_mayoreo
                  }],
                  usuario: self.$local_storage.get("sb_usuario"),
                  observaciones: "Ajuste Precio por Artículo",
              };
              this.model_etiqueta = etq;

              window.axios
                  .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', etq)
                  .then(response => {
                      this.model_etiqueta["_rev"] = response.data.rev;
                      this.model_etiqueta["_id"] = response.data.id;
                      //console.log("Response", response);
                      window.dialogLoader.showSnackbar('Solicitud Enviada', {
                        color: 'success'
                      });

                  })
                  .catch(error => {
                      console.log(error);
                      window.dialogLoader.showSnackbar('Ocurrió un error al crear la solicitud. Intente nuevamente', {
                        color: 'error'
                      });
                  });
            }

        },
        ordenar_nombre_prov: function( a, b ){
            if ( a.nombre.toLowerCase() < b.nombre.toLowerCase()){
                return -1;
            }
            if ( a.nombre.toLowerCase() > b.nombre.toLowerCase()){
                return 1;
            }
            return 0;
        },
        getProveedoresCombo() {
            this.loadingProveedores = true;
            let data = {
                "selector": {
                    "type": "proveedores",
                    "estatus":"Activo"
                },
                //"fields":["_id", "nombre"],
                "use_index":"_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    response.data.docs.sort(this.ordenar_nombre_prov);
                    this.proveedores_combo = response.data.docs;
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los proveedores.",
                        footer: ""
                    });
                }).then(()=>{
                    this.loadingProveedores=false;
                });
        },

        getProveedor: function(id_proveedor){
            var filtro = this.proveedores_combo.find(function(e){
                return e._id == id_proveedor;
            });
            if(filtro){
                return filtro;
            } else {
                return false;
            }
        },

        consultarProveedor_combo: async function () {
            if(this.id_proveedor != null && this.id_proveedor!=""){
                window.dialogLoader.show('Espere un momento por favor..');
                var self = this;
                self.registros.proveedor = [];
                
                var busca = encodeURI(escape(self.id_proveedor));
                window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/articulos_by_proveedor?key=\"'+busca+'\"')
                .then(async response => {

                    if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
                        var rows = response.data.rows;
                      
                        await Promise.all(rows.map(async (item) => {
                            var articulo = item.value;

                            let datos_consulta = {
                                "id_articulo": articulo._id,
                            };

                            await self.calcula_precio_promedio_row(articulo._id).then(async precioPromedio => {
                                articulo["precio_compra_promedio"] = precioPromedio;
                            }).catch(err => {
                                console.log(err);
                                console.log("Ocurrió un error al obtener precio promedio de compra de artículo: ", articulo._id);
                            });

                            await window.funciones_lotes.consultaExistencia(datos_consulta).then(async result => {
                                articulo["existencia"] = 0;
                                if (result.length > 0) {
                                    result.forEach(el => {
                                        articulo["existencia"] += el.existencia;
                                    });
                                }                                                                     
                            }).catch(err => {
                                console.log("Ocurrió un error al consultar existencia de artículo: ", articulo._id);
                            });

                            if (articulo.precio_proveedor == undefined || articulo.precio_proveedor == "")
                                articulo.precio_proveedor = 0.00;

                            articulo.precio_proveedor = parseFloat(articulo.precio_compra_con_iva).toFixed(2);
                            
                            if (articulo.precios_venta != undefined && articulo.precios_venta.length > 0) {
                                articulo.publico = "N/A";
                                articulo.mayoreo = "N/A";
                                articulo.mayoreo2 = "N/A";
                                articulo.precios_venta.forEach(function (y) {
                                    //console.log("Precios: ", x);
                                    if (y.tipo_precio == "Público General") {
                                        if(y.precio_con_impuestos != undefined)
                                            articulo.publico = parseFloat(y.precio_con_impuestos).toFixed(2);
                                        else
                                            articulo.publico = parseFloat(0.00).toFixed(2);
                                    }
                                    if (y.tipo_precio == "Mayoreo") {
                                        if(y.precio_con_impuestos != undefined)
                                            articulo.mayoreo = parseFloat(y.precio_con_impuestos).toFixed(2);
                                        else
                                            articulo.mayoreo = parseFloat(0.00).toFixed(2);
                                    }
                                    if (y.tipo_precio == "Mayoreo 2") {
                                        if(y.precio_con_impuestos != undefined)
                                            articulo.mayoreo2 = parseFloat(y.precio_con_impuestos).toFixed(2);
                                        else 
                                            articulo.mayoreo2 = parseFloat(0.00).toFixed(2);
                                    }
                                })
                            } 

                            articulo["tipo_articulo"] = "Compra";
                            self.registros.proveedor.push(articulo);

                            //Traemos recursivamente los articulos equivalentes
                            await this.get_equivalencias(articulo._id, self.registros.proveedor);

                        }));

                        self.registros.proveedor.sort(this.ordenar_nombre);

                    } else {                        
                        self.$swal({
                            type: "warning",
                            title: "Proveedor sin artículos!",
                            text: "El proveedor, no tiene artículos relacionados. Favor de verificar.",
                            footer: ""
                        });
                    }
                })
                .catch(err => {
                    console.log("Error en consultarProveedor: ", err);
                }).then(()=>{
                    window.dialogLoader.hide();
                });
            }

        },
        get_equivalencias: async function(id_articulo, articulos_con_equivalentes){
            var self = this;
            var busca = encodeURI(escape(id_articulo));
            await window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/equivalencias?key=\"' + busca + '\"')
            .then(async responseEquivalencias => {
                if (responseEquivalencias.data != null && responseEquivalencias.data.rows != null && responseEquivalencias.data.rows.length > 0) {
                    await Promise.all(responseEquivalencias.data.rows.map(async (item) => {
                    //for(var ke in responseEquivalencias.data.rows){
                        var equivalencia = item.value;
                        await window.axios
                        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + equivalencia.id_articulo_equivalente)
                        .then(async responseArticulo => {
                            try{
                                var articulo = responseArticulo.data;
                                
                                let datos_consulta = {
                                    "id_articulo": articulo._id,
                                };

                                await self.calcula_precio_promedio_row(articulo._id).then(async precioPromedio => {
                                    articulo["precio_compra_promedio"] = precioPromedio;
                                }).catch(err => {
                                    console.log(err);
                                    console.log("Ocurrió un error al obtener precio promedio de compra de artículo: ", articulo._id);
                                });

                                await window.funciones_lotes.consultaExistencia(datos_consulta).then(async result => {
                                    articulo["existencia"] = 0;
                                    if (result.length > 0) {
                                        result.forEach(el => {
                                            articulo["existencia"] += el.existencia;
                                        });
                                    }                                                                     
                                }).catch(err => {
                                    console.log("Ocurrió un error al consultar existencia de artículo: ", articulo._id);
                                });

                                if (articulo.precio_proveedor == undefined || articulo.precio_proveedor == "")
                                    articulo.precio_proveedor = 0.00;

                                articulo.precio_proveedor = parseFloat(articulo.precio_compra_con_iva).toFixed(2);
                                
                                if (articulo.precios_venta != undefined && articulo.precios_venta.length > 0) {
                                    articulo.publico = "N/A";
                                    articulo.mayoreo = "N/A";
                                    articulo.mayoreo2 = "N/A";
                                    articulo.precios_venta.forEach(function (y) {
                                        //console.log("Precios: ", x);
                                        if (y.tipo_precio == "Público General") {
                                            if(y.precio_con_impuestos != undefined)
                                                articulo.publico = parseFloat(y.precio_con_impuestos).toFixed(2);
                                            else
                                                articulo.publico = parseFloat(0.00).toFixed(2);
                                        }
                                        if (y.tipo_precio == "Mayoreo") {
                                            if(y.precio_con_impuestos != undefined)
                                                articulo.mayoreo = parseFloat(y.precio_con_impuestos).toFixed(2);
                                            else
                                                articulo.mayoreo = parseFloat(0.00).toFixed(2);
                                        }
                                        if (y.tipo_precio == "Mayoreo 2") {
                                            if(y.precio_con_impuestos != undefined)
                                                articulo.mayoreo2 = parseFloat(y.precio_con_impuestos).toFixed(2);
                                            else 
                                                articulo.mayoreo2 = parseFloat(0.00).toFixed(2);
                                        }
                                    })
                                } 

                                articulo["tipo_articulo"] = "Equivalente";
                                articulos_con_equivalentes.push(articulo);
                                await this.get_equivalencias(articulo["_id"], articulos_con_equivalentes);
                            } catch(err){
                                console.log("ERROR al leer articulo equivalente: ",err);
                            }
                        }).catch(error => {
                            console.log("Error al obtener articulo equivalente",error);                            
                        })
                    }));
                }
            }).catch(error => {
                console.log("Error al buscar equivalencias",error);                            
            });
        },
        ordenar_nombre: function( a, b ){//Ordenar por dos propiedades   
            if (a.nombre.toLowerCase() === b.nombre.toLowerCase()){
                return a.tipo_articulo.toLowerCase() < b.tipo_articulo.toLowerCase() ? -1 : 1
            } else {
                return a.nombre.toLowerCase() < b.nombre.toLowerCase() ? -1 : 1
            }         
            /*if ( (a.nombre_articulo.toLowerCase()+a.tipo_articulo.toLowerCase()) < (b.nombre_articulo.toLowerCase()+b.tipo_articulo.toLowerCase())){
                return -1;
            }
            if ( (a.nombre_articulo.toLowerCase()+a.tipo_articulo.toLowerCase()) > (b.nombre_articulo.toLowerCase()+b.tipo_articulo.toLowerCase())){
                return 1;
            }*/
            return 0;
        },
        selectRow(row) {          
          this.selectedRowIndex = row.row._id;          
        },
        getRowClass(row) {          
          return this.selectedRowIndex === row._id ? 'selected-row' : '';
        }

  }
};
</script>
<style scoped>
.elevation-2 >>> .VueTables__row.selected-row {
  background-color: #d3f2dc !important;
}
.footer-hide>>>.VuePagination {
  display: none;
}

.table-bordered thead th,
.table-bordered thead td {
  font-size: x-small !important;
}
.bg-amarillo {
  background-color: #dae115 !important;
}
</style>
